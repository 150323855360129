<template>
  <b-card title="政策模板">
    <b-row
      v-if="list.length>0"
      v-loading="loading"
    >
      <b-col
        v-for="(item,index) in list"
        :key="index"
        xl="3"
        lg="3"
        md="3"
      >
        <div
          class="card-list mb10"
          @click="toPolicyEdit(item.code)"
        >
          <div class="card-list-header">
            <h4 class="card-list-title overflow-ellipsis">
              {{ item.templateName }}
            </h4>
            <div class="card-line mb-0" />
          </div>
          <div class="card-list-body">
            <span
              class="overflow-ellipsis card-list-abstract"
              style="line-height: 1.5rem;"
            >{{ item.description }}</span>
          </div>
          <div
            v-show="btnPermsObj.savePerm"
            class="text-right card-list-operate"
          >
            <el-link
              :underline="false"
              class="mr10"
              type="primary"
            >
              <span class="font-smaller-3 flex-between">
                <i
                  class="iconfont el-icon-s-promotion"
                  @click="toPolicyEdit(item.code)"
                />
                <span class="f12">新建</span>
              </span>
            </el-link>
          </div>
        </div>
      </b-col>
    </b-row>
    <el-empty
      v-else
      description="暂无数据"
    />
  </b-card>
</template>

<script>
import { error, findBtnPerm } from '@core/utils/utils'
import { DownloadTemplateFile, getPrivacyPolicyByCurrent, ListTemplate } from '@/api/enterprise/prolicy'

export default {
  name: 'PolicyCard',
  props: {
    btnPermsObj: {
      type: Object,
    },
  },
  data() {
    return {
      accessToken: localStorage.getItem('accessToken'),
      dialogVisible: false,
      loading: false,
      list: [],
      policyInfo: {
        id: '',
        title: '',
        issueNo: '',
        abstract: '',
        compliance: '',
      },
    }
  },
  created() {
    this.getPrivacyPolicyByCurrent()
  },
  methods: {
    downloadFile(item) {
      DownloadTemplateFile(item.id)
    },
    toPolicyFile(row) {
      const { href } = this.$router.resolve({
        path: '/policyFile',
        query: { id: row.id },
      })
      window.open(href, '_blank')
    },
    detailDialogShow(item) {
      this.$refs.policyDetail.dialogVisible = true
      this.$refs.policyDetail.policyId = item.id
    },
    getPrivacyPolicyByCurrent() {
      this.loading = true
      ListTemplate()
        .then(res => {
          this.loading = false
          const resData = res.data
          // eslint-disable-next-line eqeqeq
          if (resData.code === 0) {
            // this.policyInfo = resData.data
            this.list = resData.data
          } else {
            error(resData.msg)
          }
        })
    },
    toPolicyEdit(id) {
      if (this.btnPermsObj.savePerm) {
        this.$router.push({
          path: '/PolicyEdit',
          query: {
            tId: id,
          },
        })
      } else {
        error('您无创建权限，请联系管理员')
      }
    },
  },
}
</script>

<style scoped>

</style>
