<template>
  <b-card>
    <div class="filter-wrap flex-between">
      <!--<SearchFilter-->
      <!--:SearchStr.sync="listQuery.SearchStr"-->
      <!--@handleFilter="handleFilter"-->
      <!--/>-->
      <div class="flex-start search-filter-wrap">
        <el-input
          v-model="listQuery.SearchStr"
          width="180"
          placeholder="请输入内容"
          clearable
          @clear="handleFilter"
        />
        <el-button
          size="small"
          type="primary"
          icon="el-icon-search"
          @click="handleFilter"
        >
          搜索
        </el-button>
      </div>
      <el-button
        v-if="btnPermsObj.importPerm"
        size="small"
        type="primary"
        icon="el-icon-plus"
        @click="saveDialogShow"
      >
        导入文件
      </el-button>
    </div>
    <FilterSelectList
      :list-query.sync="listQuery"
      :select-list.sync="selectList"
      @handleFilter="handleFilter"
    />
    <el-table
      v-loading="loading"
      fit
      border
      highlight-current-row
      :data="tableData"
      stripe
      style="width: 100%"
      @sort-change="sortChange"
    >
      <el-table-column
        prop="title"
        :show-overflow-tooltip="true"
        label="标题"
      />
      <el-table-column
        prop="issueNo"
        :show-overflow-tooltip="true"
        sortable
        width="120"
        label="编号"
      >
        <template
          slot="header"
          slot-scope="scope"
        >
          <TableHeaderFilterDropdown
            :key-str="'issueNo'"
            :select-list.sync="selectList"
            :label="'编号'"
            :value.sync="listQuery.issueNo"
            :dropdown-options="issueNoOptions"
            @handleFilter="handleFilter"
          />
        </template>
        <template slot-scope="{row}">
          {{ row.issueNo }}
        </template>
      </el-table-column>
      <el-table-column
        :show-overflow-tooltip="true"
        prop="area"
        width="120"
        label="地区"
      />
      <el-table-column
        :show-overflow-tooltip="true"
        prop="abstract"
        label="简介"
      />
      <el-table-column
        :show-overflow-tooltip="true"
        prop="type"
        label="来源"
        width="100"
      >
        <template slot-scope="{row}">
          {{ templateTypeObj[row.type] }}
        </template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
        width="100"
      >
        <template slot-scope="{row}">
          {{ formatDateStr(row.createTime) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="ActualName"
        label="创建人"
        width="80"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        label="操作"
        width="165px"
      >
        <template slot-scope="{row}">
          <el-link
            v-if="btnPermsObj.savePerm"
            :underline="false"
            class="mr10"
            type="primary"
            @click="copyPolicy(row)"
          >
            <el-tooltip
              content="复制"
              placement="top"
              effect="light"
            >
              <i
                :class="row.type==1 ? 'not-allow' : ''"
                class="el-icon-document-copy"
              />
            </el-tooltip>
          </el-link>
          <el-link
            v-if="btnPermsObj.updatePerm"
            class="mr10"
            :underline="false"
            type="primary"
            @click="updateRow(row)"
          >
            <el-tooltip
              content="编辑"
              placement="top"
              effect="light"
            >
              <i class="el-icon-edit" />
            </el-tooltip>
          </el-link>
          <el-link
            v-if="btnPermsObj.readPerm"
            class="mr10"
            :underline="false"
            type="primary"
            @click="preview(row)"
          >
            <el-tooltip
              content="预览"
              placement="top"
              effect="light"
            >
              <i class="el-icon-document" />
            </el-tooltip>
          </el-link>
          <el-link
            v-if="btnPermsObj.downloadPerm"
            class="mr10"
            type="primary"
            @click="downloadFile(row)"
          >
            <el-tooltip
              content="下载文件"
              placement="top"
              effect="light"
            >
              <i class="el-icon-download" />
            </el-tooltip>
          </el-link>
          <el-link
            v-if="btnPermsObj.delPerm"
            :underline="false"
            type="primary"
            @click="del(row)"
          >
            <el-tooltip
              content="删除"
              placement="top"
              effect="light"
            >
              <i class="el-icon-delete" />
            </el-tooltip>
          </el-link>
        </template>
      </el-table-column>
    </el-table>
    <!--<pagination-->
    <!--v-show="total>0"-->
    <!--:total="total"-->
    <!--:page.sync="listQuery.page"-->
    <!--:limit.sync="listQuery.pageSize"-->
    <!--@pagination="getList"-->
    <!--/>-->
    <PolicySave
      ref="policySave"
      @handleFilter="handleFilter"
    />
    <PageQuery
      v-show="total>0"
      :total="total"
      :cache-name="'listQuery1'"
      :update-state="'UPDATE_LISTQUERY1'"
      :list-query.sync="listQuery"
      :init-list-query.sync="initListQuery"
      @pagination="getList"
    />
  </b-card>
</template>

<script>
import pagination from '@core/components/pagination/Pagination.vue'
import PolicySave from '@/views/privacyFramework/policy/PolicySave.vue'
import {
  privacyPolicyPage, policyDel, GetIssueNoList, DownloadPrivacyFile,
} from '@/api/enterprise/prolicy'
import {
  error, success, getBtnPerms, verifyBtnPerm,
} from '@core/utils/utils'
import TableHeaderFilterDropdown from '@core/components/table-header-fliter-dropdown/TableHeaderFilterDropdown.vue'
import FilterSelectList from '@core/components/filter-select-list/FilterSelectList.vue'

export default {
  name: 'PolicyTable',
  components: {
    pagination,
    PolicySave,
    TableHeaderFilterDropdown,
    FilterSelectList,
  },
  props: {
    btnPermsObj: {
      type: Object,
    },
  },
  data() {
    const listQuery = {
      page: 1,
      pageSize: 5,
      SearchStr: '',
      issueNo: '',
      sort: '',
      orderBy: {
        issueNo: '',
      },
    }
    return {
      loading: false,
      childPage: ['/PolicyEdit'],
      total: 0,
      templateTypeObj: {
        1: '导入',
        2: '模板',
      },
      listQuery: { ...listQuery },
      initListQuery: { ...listQuery },
      tableData: [],
      selectList: [],
      issueNoOptions: [],
    }
  },
  created() {
    const cacheQuery = this.$store.state.pageQuery.listQuery1
    this.listQuery = { ...cacheQuery }
    this.getList()
    this.getIssueNoList()
  },
  methods: {
    sortChange(column) {
      const order = column.order ? column.order : 'ascending'
      const orderObj = {
        descending: 'desc',
        ascending: 'asc',
      }
      if (column.prop === 'issueNo') {
        this.listQuery.orderBy.issueNo = orderObj[order]
      }
      this.getList()
    },
    downloadFile(row) {
      DownloadPrivacyFile(row.id)
    },
    preview(row) {
      const routeUrl = this.$router.resolve({
        path: '/policy-read',
        query: {
          id: row.id,
          t: row.type,
        },
      })
      window.open(routeUrl.href, '_blank')
    },
    saveDialogShow() {
      this.$refs.policySave.dialogVisible = true
    },
    copyPolicy(row) {
      if (row.type === 2) {
        this.$router.push({
          path: '/PolicyEdit',
          query: {
            pId: row.id,
            c: 1,
          },
        })
      }
    },
    updateRow(row) {
      if (row.type === 1) {
        this.$refs.policySave.dialogVisible = true
        this.$refs.policySave.form.title = row.title
        this.$refs.policySave.form.id = row.id
        this.$refs.policySave.form.issueNo = row.issueNo
        this.$refs.policySave.form.area = row.area
        this.$refs.policySave.form.displayFile = row.displayFile
        this.$refs.policySave.form.remark = row.abstract
      } else {
        this.$router.push({
          path: '/PolicyEdit',
          query: {
            pId: row.id,
          },
        })
      }
    },
    getList() {
      this.loading = true
      this.tableData = []
      privacyPolicyPage(this.listQuery)
        .then(res => {
          this.loading = false
          this.tableData = res.data.data.list
          this.total = Number(res.data.data.total)
        })
        .catch(() => {
          this.loading = false
        })
    },
    getIssueNoList() {
      GetIssueNoList()
        .then(res => {
          if (res.data.code === 0) {
            res.data.data.forEach(item => {
              this.issueNoOptions.push({
                label: item,
                value: item,
              })
            })
          }
        })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
    del(row) {
      this.$confirm('是否确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      })
        .then(() => {
          policyDel({ id: row.id })
            .then(res => {
              const resData = res.data
              if (resData.code === 0) {
                success(resData.msg)
                this.handleFilter()
              } else {
                error(resData.msg)
              }
            })
        })
    },
  },
}
</script>

<style scoped>

</style>
