<template>
  <el-dialog
    title="基本属性"
    :visible.sync="dialogVisible"
    width="620px"
    @close="dialogClose"
    @open="dialogOpen"
  >
    <el-form
      v-if="dialogVisible"
      ref="form"
      v-loading="loading"
      :model="form"
      label-width="60px"
      class="mr10"
    >
      <el-form-item
        label="标题"
        prop="title"
        :rules="[ { required: true, message: '标题不能为空', trigger: 'change' }]"
      >
        <el-input
          v-model="form.title"
          maxlength="100"
          placeholder="请输入标题"
        />
      </el-form-item>
      <el-form-item
        label="编号"
      >
        <el-input
          v-model="form.issueNo"
          placeholder="请输入编号"
          maxlength="256"
        />
      </el-form-item>
      <el-form-item
        label="简介"
      >
        <el-input

          v-model="form.remark"
          placeholder="请输入简介"
          type="textarea"
        />
      </el-form-item>
      <el-form-item
        label="地区"
        prop="area"
        :rules="[{ required: true, message: '请输入地区', trigger: 'change' }]"
      >
        <el-input

          v-model="form.area"
          placeholder="请输入地区"
          maxlength="100"
        />
        <!--<el-cascader-->
        <!--v-model="form.area"-->
        <!--placeholder="请选择地区"-->
        <!--size="medium"-->
        <!--:props="countryProps"-->
        <!--:show-all-levels="false"-->
        <!--:options="countryTreeOptions"-->
        <!--filterable-->
        <!--/>-->
      </el-form-item>
      <input
        id="uploadFileInput"
        type="file"
        accept=".doc, .docx, .pdf"
        name="file"
        style="display: none"
        @change="fileChange"
      >
      <el-form-item
        label="附件"
        prop="displayFile"
        :rules="form.id ? [] : [ { required: true, message: '请上传文件', trigger: 'change' }]"
      >
        <el-input
          v-model="form.displayFile"
          placeholder="请输入内容"
          readonly
        >
          <template slot="append">
            <div
              style="width: 100%;cursor:pointer"
              @click="fileInputShow"
            >
              上传文件
            </div>
          </template>
        </el-input>
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button
        type="primary"
        :disabled="loading"
        @click="submitForm"
      >
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { saveOrUpdateByFile } from '@/api/enterprise/prolicy'
import { error, success } from '@core/utils/utils'
import { getAllLawList } from '@/api/complaince/complaince'

export default {
  name: 'PolicyEdit',
  data() {
    return {
      countryProps: {
        emitPath: false,
        multiple: true,
      },
      dialogVisible: false,
      loading: false,
      form: {
        id: '',
        title: '',
        issueNo: '',
        remark: '',
        displayFile: '',
        area: '',
      },
      accessToken: localStorage.getItem('accessToken'),
    }
  },
  methods: {
    fileChange() {
      const uploadDom = document.getElementById('uploadFileInput')
      const fileVal = uploadDom.files
      this.form.displayFile = fileVal[0].name
      // const formData = new FormData()
      // formData.append('file', uploadDom.files[0])
    },
    fileInputShow() {
      document.getElementById('uploadFileInput').click()
    },
    submitForm() {
      // eslint-disable-next-line consistent-return
      this.$refs.form.validate(valid => {
        // eslint-disable-next-line no-empty
        if (valid) {
          const uploadDom = document.getElementById('uploadFileInput')
          const formData = new FormData()
          if (uploadDom.files[0]) {
            formData.append('file', uploadDom.files[0])
          }
          formData.append('title', this.form.title)
          formData.append('issueNo', this.form.issueNo)
          formData.append('remark', this.form.remark)
          // formData.append('area', this.form.area.join(','))
          formData.append('area', this.form.area)
          let saveType = 1
          if (this.form.id) {
            saveType = 2
            formData.append('id', this.form.id)
          }
          this.loading = true
          saveOrUpdateByFile(saveType, formData).then(res => {
            this.loading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.dialogVisible = false
              this.$emit('handleFilter')
            } else {
              error(resData.msg)
            }
          })
        } else {
          return false
        }
      })
    },
    dialogOpen() {

    },
    dialogClose() {
      this.form = {
        id: '',
        title: '',
        issueNo: '',
        remark: '',
        displayFile: '',
        area: '',
      }
    },
  },
}
</script>

<style>
</style>
