<template>
  <div>
    <PageIntroduce
      :title="'隐私政策'"
      :desc="'企业应按法律法规要求制定企业的个人信息保护政策，制定个人信息保护相关的各项流程和规范。'"
      :tips="'中国个人信息保护法 第五十一条'"
    />
    <ProlicyTable :btn-perms-obj="btnPermsObj" />
    <ProlicyCard :btn-perms-obj="btnPermsObj" />
  </div>
</template>
<script>
// eslint-disable-next-line import/extensions
import ProlicyTable from '@/views/privacyFramework/policy/PolicyTable'
import ProlicyCard from '@/views/privacyFramework/policy/PolicyCard.vue'
import PageIntroduce from '@core/components/pageIntroduce/PageIntroduce.vue'
import {
  error, success, getBtnPerms, verifyBtnPerm,
} from '@core/utils/utils'

export default {
  name: 'Policy',
  components: { PageIntroduce, ProlicyTable, ProlicyCard },
  data() {
    return {
      btnPermsObj: {
        savePerm: false,
        updatePerm: false,
        readPerm: false,
        downloadPerm: false,
        delPerm: false,
        importPerm: false,
      },
    }
  },
  created() {
    getBtnPerms(btnPerms => {
      this.btnPermsObj.savePerm = verifyBtnPerm(btnPerms, 'policy-2')
      this.btnPermsObj.updatePerm = verifyBtnPerm(btnPerms, 'policy-3')
      this.btnPermsObj.delPerm = verifyBtnPerm(btnPerms, 'policy-4')
      this.btnPermsObj.importPerm = verifyBtnPerm(btnPerms, 'policy-7')
      this.btnPermsObj.downloadPerm = verifyBtnPerm(btnPerms, 'policy-5')
      this.btnPermsObj.readPerm = verifyBtnPerm(btnPerms, 'policy-6')
    })
  },
}
</script>
<style>
  .policy-header{
    position: relative;
  }
  .policy-btn{
    position: absolute;
    top: -6px;
    right: 10px;
  }
  .abstract{
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
  }
</style>
